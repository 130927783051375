<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">

                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="row">
                                        <div class="col-6">
                                            <v-text-field class="w-50" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                        <div class="col-6 text-right">
                                            <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="populateMPF()">Refresh<v-icon right dark>mdi-autorenew</v-icon></v-btn>
                                        </div>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.approve1_date`]="{ item }">
                                <span v-if="item.approve1_date != '01-01-1900 00:00'">{{ item.approve1_date }}</span>
                            </template>
                            <template v-slot:[`item.approve2_date`]="{ item }">
                                <span v-if="item.approve2_date != '01-01-1900 00:00'">{{ item.approve2_date }}</span>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <div v-if="item.stat == 'P' && isApprove1 == true" class="d-flex justify-content-end">
                                    <v-btn class="mr-3" color="success" elevation="2" small rounded @click="approveMPF(item)">Approve</v-btn>
                                    <v-btn class="mr-3" color="red" elevation="2" small rounded @click="rejectMPF(item)" dark>Reject</v-btn>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="showItemDetails(item)">Details</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="printMPF(item)">Print</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                                <div v-else-if="item.stat == 'N' && isApprove1 == true" class="d-flex justify-content-end">
                                    <v-chip color="red" class="mt-1 mr-3" small dense dark>REJECTED</v-chip>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="showItemDetails(item)">Details</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="printMPF(item)">Print</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                                <div v-else-if="item.stat == 'X' && isApprove1 == true" class="d-flex justify-content-end">
                                    <v-chip color="red" class="mt-1 mr-3" small dense dark>REJECTED</v-chip>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="showItemDetails(item)">Details</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="printMPF(item)">Print</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                                <div v-else-if="item.stat == 'S' && isApprove1 == true" class="d-flex justify-content-end">
                                    <v-chip color="success" class="mt-1 mr-3" small dense dark>ACCEPTED</v-chip>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="showItemDetails(item)">Details</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="printMPF(item)">Print</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                                <div v-else class="d-flex justify-content-end">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="showItemDetails(item)">Details</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="printMPF(item)">Print</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </template>
                        </v-data-table>

                    </div>
                </div>

            </div>

            <v-dialog v-model="dialogDetailMpf" max-width="720px" >
            
                <div class="card elevation-5">
                    <!-- <div class="card-header bg-transparent border-bottom-2 text-right">
                        <v-btn class="" color="red" elevation="2" small @click="dialogDetailMpf = false"><v-icon color="white">mdi-close</v-icon></v-btn>
                    </div> -->
                    <div class="card-body">
                        <h5 class="card-title">Detail MPF</h5>
                        <h5 class="card-title">Attachment : <v-chip color="blue" v-if="imageDetail" @click="showImage()" small dense dark> Click for view attachment </v-chip> <v-chip color="red" v-else small dense dark> No Attachment </v-chip> </h5>
                        <h5 class="card-title">Remark : </h5>
                        <div class="row">
                            <div class="col-lg-12 col-12 d-flex">
                                <ul>
                                    <li v-for="item in mpfDetailsList" :key="item.seq_no">
                                        <span>{{ item.formattedDate }} | {{ item.created_by.toUpperCase() }} : {{ item.message_body }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="mpfStat != 'X'" class="col-lg-12 col-12 d-flex">
                                <v-text-field class="pt-5" v-model="addCommentModel" label="Add comment here..." dense></v-text-field>
                                <v-btn class="mt-4" color="success" :disabled='addCommentBtnDisable' elevation="2" small @click="addComment()" dense>{{ addCommentBtnStr }}</v-btn>
                            </div>
                            <!-- <div v-if="mpfStat != 'N' && isApprove1 == true" class="col-lg-12 col-12 d-flex justify-content-end">
                                <v-btn class="mt-3 ml-3 mr-3" color="success" elevation="2" small rounded @click="approveMPF()">Approve</v-btn>
                                <v-btn class="mt-3" color="red" elevation="2" small rounded @click="rejectMPF()" dark>Reject</v-btn>
                            </div> -->
                        </div>
                    </div>
                </div>
            
            </v-dialog>

            <v-dialog v-model="dialogShowImage" max-width="768px" >
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-img :src = imgUrl ></v-img>
                    </div>
                </div>
            </v-dialog>

        </div>

    </div>
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Viva',
                disabled: false,
                href: '/admin/viva',
                },
                {
                text: 'MPF',
                disabled: false,
                href: '#',
                },
                {
                text: 'MPF Outbox',
                disabled: true,
                href: '#',
                },
            ],
            headers: [
                { text: 'ID', value: 'tr_id', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'FROM', value: 'fromStr', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'TO', value: 'toStr', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'SUBJECT', value: 'cat_desc', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'DATE', value: 'created_date', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'APPROVE 1', value: 'approve1_date', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'APPROVE 2', value: 'approve2_date', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: '', value: 'actions', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
            ],
            itemLists: [],
            searchItem: '',
            loadingDatatable:false,
            mpfDetailTrId: '',
            isApprove1: false,
            mpfStat: '',
            dialogDetailMpf: false,
            imageDetail: '',
            imgUrl: '',
            dialogShowImage: false,
            mpfDetailsList: [],
            addCommentModel: '',
            addCommentBtnDisable: false,
            addCommentBtnStr: 'Add Comment'
        }
    },
    async mounted(){
        
        this.initialize()
        
    },
    methods:{

        async initialize(){
            
            this.populateMPF()

        },

        async populateMPF(){

            this.$store.dispatch('setOverlay', true)
            this.loadingDatatable = true
            
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/MPFOutbox/getData`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false

                if(res.data.mpf_group != 'false'){

                    if(res.data.isApprove == '1'){
                        this.isApprove1 = true
                    }

                    this.itemLists = res.data.mpfList

                } else {

                    Swal.fire({
                        title: 'Access Denied',
                        text: "You won't be able to create MPF, please call IT Administrator!",
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        this.$router.push('/admin/viva/dashboard');
                    })

                }
                
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async showItemDetails(id){

            this.$store.dispatch('setOverlay', true)

            this.mpfDetailTrId = id.tr_id

            await axios.get(`${process.env.VUE_APP_URL}/api/viva/MPFOutbox/getDetails?id=${id.tr_id}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.mpfStat = id.stat
                this.dialogDetailMpf = true
                this.mpfDetailsList = res.data.result
                this.imageDetail = res.data.image

            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        showImage(){

            this.imgUrl = process.env.VUE_APP_URL + this.imageDetail
            this.dialogShowImage = true

        },

        approveMPF(id){

            Swal.fire({
                text: 'Do you want Approve this MPF ?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: 'green',
                denyButtonText: 'Cancel',
                denyButtonColor: 'red'
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFOutbox/approveMPF`, {

                        id: id.tr_id ? id.tr_id : ''

                    },
                    { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        this.populateMPF()
                        
                    })
                    .catch(err => {
                        
                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401') {
                            this.$router.push('/');
                        } else {
                            Swal.fire({
                                text: err.response.data.data,
                                icon: 'error',
                            })
                        }

                    })

                }
            })

        },

        rejectMPF(id){

            Swal.fire({
                text: 'Do you want Reject this MPF ?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: 'green',
                denyButtonText: 'Cancel',
                denyButtonColor: 'red'
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFOutbox/rejectMPF`, {

                        id: id.tr_id ? id.tr_id : ''

                    },
                    { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        this.populateMPF()
                        
                    })
                    .catch(err => {
                        
                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401') {
                            this.$router.push('/');
                        } else {
                            Swal.fire({
                                text: err.response.data.data,
                                icon: 'error',
                            })
                        }

                    })

                }
            })

        },

        async addComment(){

            if(this.addCommentModel){

                this.addCommentBtnDisable = true
                this.addCommentBtnStr = 'Loading...'

                await axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFOutbox/addCommentMPF`, {

                    id: this.mpfDetailTrId ? this.mpfDetailTrId : '',
                    remark: this.addCommentModel ? this.addCommentModel : '',

                },
                { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.addCommentBtnDisable = false
                    this.addCommentBtnStr = 'Add Comment'

                    this.mpfDetailsList = []
                    this.mpfDetailsList = res.data.result
                    this.addCommentModel = ''
                    
                })
                .catch(err => {
                    
                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401') {
                        this.$router.push('/');
                    } else {
                        Swal.fire({
                            text: err.response.data.data,
                            icon: 'error',
                        })
                    }

                })

            }

        },

        printMPF(id){

            this.$router.push(`/printMPF/${id.tr_id}`);

        }

    },
    watch: {

        
    }   
}

</script>
